import { VDataIterator } from 'vuetify/lib/components/VDataIterator';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BasePanelWithHeader',{attrs:{"headerText":"Exceptions"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ExciseExceptionsParams',{attrs:{"disabled":_vm.datasheetUnsavedItems,"showSort":true,"headers":_vm.filteredHeaders}}),_c(VDataIterator,{attrs:{"items":_vm.exciseExceptions,"server-items-length":_vm.searchTotal,"loading":_vm.loading,"sort-by":_vm.searchSortBy,"sort-desc":_vm.searchDescending,"page":_vm.searchPage,"items-per-page":_vm.searchRowsPerPage,"footer-props":{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 250, 500],
        disablePagination: _vm.datasheetUnsavedItems,
        disableItemsPerPage: _vm.datasheetUnsavedItems,
      }},on:{"update:sortBy":function($event){_vm.searchSortBy=$event},"update:sort-by":function($event){_vm.searchSortBy=$event},"update:sortDesc":function($event){_vm.searchDescending=$event},"update:sort-desc":function($event){_vm.searchDescending=$event},"update:page":function($event){_vm.searchPage=$event},"update:itemsPerPage":function($event){_vm.searchRowsPerPage=$event},"update:items-per-page":function($event){_vm.searchRowsPerPage=$event}},scopedSlots:_vm._u([{key:"footer.prepend",fn:function(){return [_c('div',{staticClass:"v-data-footer__select flex-grow-1"},[_vm._v(" Selected Page: "),_c(VSelect,{attrs:{"disabled":_vm.datasheetUnsavedItems,"hide-details":"","items":Array.from(
                {length: Math.ceil(_vm.searchTotal / _vm.searchRowsPerPage)},
                function (v, i) { return i + 1; }
              )},model:{value:(_vm.searchPage),callback:function ($$v) {_vm.searchPage=$$v},expression:"searchPage"}})],1)]},proxy:true}])},[_c('ExciseExceptionsToastGrid',{ref:"exceptionGrid",attrs:{"mode":"edit","items":_vm.exciseExceptions},on:{"itemAdded":_vm.itemAdded,"itemModified":_vm.itemModified,"bulkSave":_vm.bulkSave}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }