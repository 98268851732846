<template>
  <BasePanelWithHeader headerText="Exceptions">
    <template v-slot:content>
      <ExciseExceptionsParams
        :disabled="datasheetUnsavedItems"
        :showSort="true"
        :headers="filteredHeaders"
      ></ExciseExceptionsParams>
      <v-data-iterator
        :items="exciseExceptions"
        :server-items-length="searchTotal"
        :loading="loading"
        :sort-by.sync="searchSortBy"
        :sort-desc.sync="searchDescending"
        :page.sync="searchPage"
        :items-per-page.sync="searchRowsPerPage"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [5, 10, 15, 25, 50, 100, 250, 500],
          disablePagination: datasheetUnsavedItems,
          disableItemsPerPage: datasheetUnsavedItems,
        }"
      >
        <ExciseExceptionsToastGrid
          mode="edit"
          ref="exceptionGrid"
          :items="exciseExceptions"
          @itemAdded="itemAdded"
          @itemModified="itemModified"
          @bulkSave="bulkSave"
        ></ExciseExceptionsToastGrid>
        <template v-slot:footer.prepend="">
          <div class="v-data-footer__select flex-grow-1">
            Selected Page:
            <v-select
            :disabled="datasheetUnsavedItems"
              hide-details
              v-model="searchPage"
              :items="
                Array.from(
                  {length: Math.ceil(searchTotal / searchRowsPerPage)},
                  (v, i) => i + 1
                )
              "
            ></v-select>
          </div>
        </template>
      </v-data-iterator>
    </template>
  </BasePanelWithHeader>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import {mapMutations} from 'vuex'
import ExciseExceptionsToastGrid from '@components/grid/ExciseExceptionsToastGrid'
import ExciseExceptionsParams from '@components/search/ExciseExceptionsParams'
export default {
  //model: {
  //	prop: ''
  //},
  props: {},
  components: {
    ExciseExceptionsToastGrid,
    ExciseExceptionsParams,
  },
  filters: {},
  data() {
    return {}
  },
  created() {
    this.searchProjectID = parseInt(this.$route.params.projectId)
    this.searchRowsPerPage = 100
    this.searchExciseExceptions()
  },
  beforeDestroy() {
    console.red('edit before destroy')
  },
  computed: {
    ...sync('exciseExceptions', [
      'searchProjectID',
      'searchTotal',
      'searchPage',
      'searchSortBy',
      'searchDescending',
      'searchRowsPerPage',
    ]),
    ...get('exciseExceptions', [
      'exciseExceptions',
      'loading',
      'datasheetUnsavedItems',
    ]),
    headers() {
      return [
        {
          text: 'ID',
          align: 'center',
          sortable: true,
          value: 'ID',
          visible: true,
        },
        {
          text: 'Stratum',
          align: 'center',
          sortable: true,
          value: 'StratumID',
          visible: true,
        },
        {
          text: 'Invoice Date',
          align: 'center',
          sortable: true,
          value: 'InvoiceDate',
          visible: true,
        },
        {
          text: 'Document Number',
          align: 'center',
          sortable: true,
          value: 'DocumentNumber',
          visible: true,
        },
        {
          text: 'Issue ID',
          align: 'center',
          sortable: true,
          value: 'IssueID',
          visible: true,
        },
        {
          text: 'Transaction Type',
          align: 'center',
          sortable: true,
          value: 'TransactionTypeID',
          visible: true,
        },
        {
          text: 'Tax Type',
          align: 'center',
          sortable: true,
          value: 'TaxTypeID',
          visible: true,
        },
        // {
        //   text: 'Sold From County',
        //   align: 'center',
        //   sortable: true,
        //   value: 'SoldFromCountyID',
        //   visible: true,
        // },
        {
          text: 'Delivered To City',
          align: 'center',
          sortable: true,
          value: 'DeliveredToCityID',
          visible: true,
        },
        {
          text: 'Delivered To County',
          align: 'center',
          sortable: true,
          value: 'DeliveredToCountyID',
          visible: true,
        },
        {
          text: 'Special District',
          align: 'center',
          sortable: true,
          value: 'SpecialDistrictID',
          visible: true,
        },
        {
          text: 'Vendor Customer',
          align: 'center',
          sortable: true,
          value: 'VendorCustomer',
          visible: true,
        },
        {
          text: 'Description',
          align: 'center',
          sortable: true,
          value: 'Description',
          visible: true,
        },
        {
          text: 'Taxable Amount',
          align: 'center',
          sortable: true,
          value: 'TaxableAmount',
          visible: true,
        },
        {
          text: 'Tax Paid',
          align: 'center',
          sortable: true,
          value: 'TaxPaid',
          visible: true,
        },
        {
          text: 'Tax Rate',
          align: 'center',
          sortable: true,
          value: 'TaxRate',
          visible: true,
        },
        {
          text: 'Tax Due',
          align: 'center',
          sortable: true,
          value: 'TaxDue',
          visible: true,
        },
        {
          text: 'Reference',
          align: 'center',
          sortable: true,
          value: 'Reference',
          visible: true,
        },
        {
          text: 'Is Active',
          align: 'center',
          sortable: true,
          value: 'IsActive',
          visible: true,
        },
        {
          text: 'Is Appended',
          align: 'center',
          sortable: true,
          value: 'IsAppended',
          visible: false,
        },
        {
          text: 'Created By',
          align: 'center',
          sortable: true,
          value: 'CreatedByID',
          visible: true,
        },
        {
          text: 'Sub Description',
          align: 'center',
          sortable: true,
          value: 'SubDescription',
          visible: false,
        },
      ]
    },
    filteredHeaders() {
      return this.headers.filter((h) => h.visible && h.sortable)
    },
  },
  methods: {
    searchExciseExceptions: call('exciseExceptions/searchExciseExceptions'),
    ...mapMutations({
      updateExceptionItemInList: 'exciseExceptions/updateExceptionItemInList',
    }),
    appendRow() {
      this.$refs.exceptionGrid.appendRow()
    },
    itemModified(data) {
      // console.devlog(data)
      // console.devlog(this)
      this.updateExceptionItemInList(data)
    },
    itemAdded(data) {
      // console.devlog(data)
      this.updateExceptionItemInList(data)
    },
    bulkSave(data) {
      // console.devlog(data)
      this.searchExciseExceptions()
    },
  },
  watch: {},
}
</script>
<style scoped>
</style>